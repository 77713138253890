import ContactUsPage from 'gatsby-theme-husky/src/templates/ContactUsPage/ContactUsPage';
import { graphql } from 'gatsby';

import './contactUsOverride.scss';

export const query = graphql`
  query ContactUsQueryOverride($pageId: String) {
    allUmbracoContactUs(filter: { id: { eq: $pageId } }) {
      nodes {
        langProps {
          urls {
            ...languageUrls
          }
          lang
        }
        seoProps {
          seoMetaTitle
          seoMetaKeywords
          seoMetaDescription
          seoExternalHreflangs {
            key
            value
          }
        }
        contactUsContent
        isSignUpBanner
      }
    }
    allSitePage {
      ...FragmentAllPageSite
    }
  }
`;

export default ContactUsPage;
