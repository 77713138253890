import React, { FC } from 'react';
import { graphql } from 'gatsby';
import Layout from 'layout/Layout';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import Seo from 'common/Seo';
import PageSchema from 'common/PageSchema';
import SignUpBanner from 'components/SignUpBanner';

import './contactUs.scss';
import createBreadcrumbs from 'utils/createBreadcrumbs';
import { toBoolean } from 'utils/stringUtils/stringUtils';

const ContactUsPage: FC<ContactUsTypes.ContactUsProps> = ({
  data: {
    allUmbracoContactUs: { nodes: umbracoContactUs },
    allSitePage: { nodes },
  },
  pageContext: {
    breadcrumb: { crumbs },
    areBreadcrumbsDisplayed,
  },
  location: { pathname },
}) => {
  const {
    langProps: { urls, lang },
    seoProps: { seoMetaTitle, seoMetaDescription, seoMetaKeywords, seoExternalHreflangs, ogImage },
    contactUsContent,
    isSignUpBanner,
  } = umbracoContactUs[0];

  const breadcrumbs = {
    breadCrumb: createBreadcrumbs(crumbs, areBreadcrumbsDisplayed, nodes, pathname),
  };

  return (
    <Layout breadcrumbs={breadcrumbs} langProps={{ urls, lang }}>
      <Seo
        {...{
          title: seoMetaTitle,
          description: seoMetaDescription,
          keywords: seoMetaKeywords,
          externalHreflangs: seoExternalHreflangs,
          ogImage,
          lang,
        }}
      />
      <PageSchema
        type="WebPage"
        name={seoMetaTitle}
        data={{
          metaTitle: seoMetaTitle,
          metaDescription: seoMetaDescription,
          metaKeywords: seoMetaKeywords,
        }}
      />
      <div className="contact-us">
        <DangerouslySetInnerHtml html={contactUsContent} />
      </div>
      {toBoolean(isSignUpBanner) ? <SignUpBanner {...{ lang }} /> : null}
    </Layout>
  );
};

export const query = graphql`
  query ContactUsQuery($pageId: String) {
    allUmbracoContactUs(filter: { id: { eq: $pageId } }) {
      nodes {
        langProps {
          urls {
            ...languageUrls
          }
          lang
        }
        seoProps {
          seoMetaTitle
          seoMetaKeywords
          seoMetaDescription
          seoExternalHreflangs {
            key
            value
          }
          ogImage
        }
        contactUsContent
        isSignUpBanner
      }
    }
    allSitePage {
      ...FragmentAllPageSite
    }
  }
`;

export default ContactUsPage;
